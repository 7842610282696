import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { ESSContainer } from '../../components/template';
import { objectMapper } from '../../utils/objectMap';
import {
  useEssTimeOffDetailQuery,
  useEssTimeOffWithdrawMutation,
} from '../../store/feature/service/essTimeOffApiSlice';
import { ActionMenuProps } from '../../@types/TableProps';
import { useAppDispatch, useAppSelector } from '../../store';
import Toast from '../../components/atom/toast/Toast';
import { removeMessage } from '../../store/feature/essTimeOffSlice';
import { FileX02 } from '../../components/atom/icon';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import Breadcrumb from '../../components/molecule/breadcrumb/Breadcrumb';
import { Title } from '../../components/molecule';
import ActionButton from '../../components/molecule/action/Action';
import WithdrawConfirmation from '../../components/organism/modal/WithdrawConfirmation/WithdrawConfirmation';
import { useDisclosure } from '../../hooks/useDisclosure';
import { detailHeaderKey } from '../../constants/timeOffRequest';
import LabelText from '../../components/atom/labelText/LabelText';
import { Button, Tags } from '../../components/atom';
import LinkExternal from '../../components/atom/icon/LinkExternal';
import {
  formatUnixToDateTime,
  newformatDateTimeFromUnixUTC,
} from '../../utils/date';
import { parseNumber } from '../../utils/parseToCurrency';
import WorkflowItem from '../../components/molecule/workflowItem/WorkflowItem';

const DetailApprovedTimeOff = () => {
  const [openWithdraw, { toggle }] = useDisclosure();
  const { palette } = useTheme();
  const params = useParams<{ id: string }>();
  const { messageSuccess, messageError } = useAppSelector(
    state => state.essTimeOff
  );
  const { data, isLoading } = useEssTimeOffDetailQuery(params.id);
  const [withdrawRequest, { isLoading: loadingWithdraw }] =
    useEssTimeOffWithdrawMutation();

  useDocumentTitle(
    isLoading ? 'TricorUnify' : `${data?.timeOffRequestId} | TricorUnify`
  );
  const navigate = useNavigate();

  const actionMenu: ActionMenuProps[] = [
    {
      name: 'withdraw',
      title: 'Withdraw',
      Icon: <FileX02 />,
    },
  ];

  const { control, handleSubmit } = useForm({ defaultValues: { reason: '' } });

  const handleClickAction = (action: string) => {
    if (action === 'withdraw') {
      toggle();
    }
  };

  const renderTagColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'approved':
        return 'success';
      case 'requested':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'warning';
    }
  };

  const currentData: { [key: string]: any } = {
    ...objectMapper(data || {}),
    type: `(${data?.typeCode ?? ''}) ${data?.typeName}`,
    attachment: data?.attachment?.fileName ? (
      <Button
        onClick={() => window.open(data?.attachment?.fileUrl, '_blank')}
        variant="link"
        sx={{ maxWidth: '300px' }}
      >
        <Typography
          variant="body14"
          fontWeight={600}
          color={palette.blue[50]}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {data?.attachment?.fileName}
        </Typography>
        <LinkExternal />
      </Button>
    ) : (
      <div>-</div>
    ),
    totalDays: parseNumber(data?.totalDays, 'EN', 1),
    dates: data?.dates
      ?.map((d: number) => newformatDateTimeFromUnixUTC(d))
      .join(', '),
  };

  const dispatch = useAppDispatch();

  const handleConfirmWithdraw = async (data: any) => {
    const payload = { remarks: data.reason };
    try {
      await withdrawRequest({ id: params?.id, payload });
      toggle();
      navigate('/ess/employee/approved-time-off');
    } catch (error) {
      console.log('ERROR :', error);
    }
  };

  return (
    <ESSContainer>
      <WithdrawConfirmation
        open={openWithdraw}
        title="Withdraw Time Off"
        caption="Are you sure want to withdraw this time off request?."
        showInputField
        handleClose={() => toggle()}
        handleConfirm={handleSubmit(handleConfirmWithdraw)}
        control={control}
        disabled={loadingWithdraw}
      />
      <Breadcrumb className="breadcrumb" />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title
          title={
            data ? (
              <Typography
                variant="body18"
                color={palette.gray[90]}
                fontWeight="700"
              >
                {data?.timeOffRequestId}{' '}
                <Tags
                  variant={renderTagColor(data?.statusName)}
                  label={data.statusName}
                />
              </Typography>
            ) : (
              ''
            )
          }
        />
        <ActionButton
          actions={actionMenu}
          onClickMenuAction={handleClickAction}
        />
      </Box>
      <Toast
        variant="success"
        open={messageSuccess !== ''}
        message={messageSuccess}
        onClose={() => dispatch(removeMessage())}
      />
      <Toast
        variant="error"
        open={messageError !== ''}
        message={messageError}
        onClose={() => dispatch(removeMessage())}
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <Grid container rowSpacing={1}>
            {Object.keys(detailHeaderKey)?.map((key: any) => (
              <Grid item xs={7} key={key}>
                <LabelText
                  headerKey={detailHeaderKey?.[key]}
                  currentData={currentData?.[key]}
                  dataPresentationStatus={data?.dataPresentationStatus}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <Typography
              variant="body16"
              fontWeight={700}
              display="block"
              color={palette.gray[90]}
            >
              Workflow
            </Typography>
          </Grid>
          {Array.isArray(data?.workflow) &&
            data?.workflow.length > 0 &&
            data?.workflow.map((wkf: any) => (
              <WorkflowItem
                key={`${wkf.fullName}${wkf.date}`}
                data={wkf}
                style={{ maxWidth: '200px' }}
                title={wkf.fullName}
                image={
                  <img
                    src={wkf.pictureUrl}
                    alt="employee profile"
                    style={{
                      width: '40px',
                      height: '40px',
                      objectFit: 'cover',
                    }}
                  />
                }
                description={formatUnixToDateTime(wkf.date)}
              />
            ))}
        </Box>
      )}
    </ESSContainer>
  );
};

export default DetailApprovedTimeOff;
